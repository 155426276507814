/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: DashboardKit
Support: dashboardkit[at]gmail[dot]com
File: style.css
=========================================================================
=================================================================================== */
.pc-horizontal .pc-header {
  left: 0;
  z-index: 1026; }
  .pc-horizontal .pc-header.pc-mob-header {
    z-index: 1027; }
  .pc-horizontal .pc-header[class*="bg-"] .material-icons-two-tone, .pc-horizontal .pc-header.dark-header .material-icons-two-tone {
    background-color: #778290; }
  .pc-horizontal .pc-header[class*="bg-"] .pc-head-link, .pc-horizontal .pc-header.dark-header .pc-head-link {
    color: #ced4dc; }
  @media (min-width: 992px) {
    .pc-horizontal .pc-header .m-header {
      margin-right: 20px; } }
  .pc-horizontal .pc-header .header-wrapper {
    padding: 0; }
    .pc-horizontal .pc-header .header-wrapper .m-header {
      display: inline-flex;
      align-items: center; }

.pc-horizontal .page-header {
  background: transparent;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  padding: 13px 0; }
  .pc-horizontal .page-header + .row {
    margin-top: 30px; }

.pc-horizontal .pc-container {
  margin-left: 0;
  top: calc(70px + 60px);
  min-height: calc(100vh - 70px - 60px); }
  .pc-horizontal .pc-container .pcoded-content {
    padding-top: 0; }
    .pc-horizontal .pc-container .pcoded-content > .row:first-child {
      padding-top: 30px; }
    .pc-horizontal .pc-container .pcoded-content .page-header {
      padding-top: 30px; }
      .pc-horizontal .pc-container .pcoded-content .page-header + .row {
        margin-top: 10px; }

.pc-horizontal.layout-topbar .pc-header[class*="bg-"] .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header.dark-header .material-icons-two-tone {
  background-color: #778290; }

.pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link {
  color: #ced4dc; }
  .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link.active, .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link:active, .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link:focus, .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link:hover, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link.active, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link:active, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link:focus, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link:hover {
    background: transparent;
    color: #7267EF; }
    .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link.active .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link:active .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link:focus .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header[class*="bg-"] .pc-head-link:hover .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link.active .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link:active .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link:focus .material-icons-two-tone, .pc-horizontal.layout-topbar .pc-header.dark-header .pc-head-link:hover .material-icons-two-tone {
      background-color: #7267EF; }

.pc-horizontal.layout-topbar .pc-header .pc-h-item .arrow-icon {
  margin-left: 4px; }
  .pc-horizontal.layout-topbar .pc-header .pc-h-item .arrow-icon svg {
    width: 15px;
    height: 15px; }

.pc-horizontal.layout-topbar .pc-container {
  top: 70px; }
  .pc-horizontal.layout-topbar .pc-container .page-header {
    padding-top: 30px; }
    .pc-horizontal.layout-topbar .pc-container .page-header + .row {
      padding-top: 0;
      margin-top: 10px; }

.pc-horizontal .topbar {
  background: #1c232f;
  top: 70px;
  left: 0;
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 1025;
  box-shadow: none;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); }
  .pc-horizontal .topbar ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .pc-horizontal .topbar .pc-micon {
    margin-right: 10px;
    line-height: 1; }
    .pc-horizontal .topbar .pc-micon > svg {
      width: 20px;
      height: 20px; }
    .pc-horizontal .topbar .pc-micon .material-icons-two-tone {
      line-height: 1;
      font-size: 22px;
      background-color: #ced4dc; }
  .pc-horizontal .topbar .pc-link {
    display: block;
    align-items: center;
    padding: 17px 10px;
    color: #b5bdca;
    font-size: 14px;
    font-weight: 500; }
    .pc-horizontal .topbar .pc-link:active, .pc-horizontal .topbar .pc-link:focus, .pc-horizontal .topbar .pc-link:hover {
      text-decoration: none;
      color: #fff; }
    .pc-horizontal .topbar .pc-link.dropdown-toggle:after {
      display: none; }
  .pc-horizontal .topbar .pc-navbar .pc-link {
    display: flex;
    align-items: center; }
  .pc-horizontal .topbar .pc-navbar > .pc-item > .pc-link > .pc-arrow {
    transform: rotate(90deg);
    margin-left: 10px; }
  .pc-horizontal .topbar .pc-item {
    display: inline-block; }
  .pc-horizontal .topbar .pc-arrow {
    margin-left: auto;
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out; }
    .pc-horizontal .topbar .pc-arrow > svg {
      width: 14px;
      height: 14px; }
  .pc-horizontal .topbar .pc-submenu {
    position: absolute;
    background: #fff;
    padding: 15px 0;
    min-width: 200px;
    border-radius: 4px;
    animation: 0.2s ease-in-out 0s normal forwards 1 fadein;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18); }
    .pc-horizontal .topbar .pc-submenu .pc-item {
      display: block;
      position: relative; }
    .pc-horizontal .topbar .pc-submenu .pc-link {
      color: #1c232f;
      position: relative;
      padding: 12px 10px 12px 20px; }
    .pc-horizontal .topbar .pc-submenu .pc-submenu {
      left: 100%;
      top: -15px;
      z-index: 1; }
      .pc-horizontal .topbar .pc-submenu .pc-submenu.edge {
        left: auto;
        right: 100%; }

.pc-horizontal .pct-customizer {
  display: none; }

.pc-horizontal.layout-horizontal-v2 .topbar {
  background: #fff; }
  .pc-horizontal.layout-horizontal-v2 .topbar .pc-item .pc-link {
    color: #1c232f; }
    .pc-horizontal.layout-horizontal-v2 .topbar .pc-item .pc-link .material-icons-two-tone {
      background-color: #525b69; }
  .pc-horizontal.layout-horizontal-v2 .topbar .pc-submenu {
    background: #fff; }
    .pc-horizontal.layout-horizontal-v2 .topbar .pc-submenu .pc-link {
      color: #1c232f; }
  .pc-horizontal.layout-horizontal-v2 .topbar .pc-hasmenu:hover > .pc-link .pc-arrow {
    transform: rotate(90deg); }

@media (min-width: 1025px) {
  .pc-horizontal .topbar .dropdown:hover > .dropdown-menu {
    display: block; }
  .pc-horizontal .topbar .pc-submenu .pc-link {
    position: relative;
    padding: 12px 20px 12px 25px; }
  .pc-horizontal .topbar .pc-submenu .pc-item:active > .pc-link, .pc-horizontal .topbar .pc-submenu .pc-item:focus > .pc-link, .pc-horizontal .topbar .pc-submenu .pc-item:hover > .pc-link {
    color: #7267EF;
    background: #e3e1fc; }
  .pc-horizontal .topbar .pc-item .pc-submenu {
    display: none; }
  .pc-horizontal .topbar .pc-item:hover > .pc-submenu {
    display: block; }
  .pc-horizontal.layout-topbar .pc-h-dropdown {
    margin-top: 0; }
  .pc-horizontal .pc-container .pcoded-content {
    padding-left: 0;
    padding-right: 0; }
  .pc-horizontal .pc-container .page-header + .row {
    padding-top: 0; }
  .pc-horizontal .topbar .pc-navbar > .pc-hasmenu {
    position: relative; }
    .pc-horizontal .topbar .pc-navbar > .pc-hasmenu:hover > .pc-link {
      z-index: 1001;
      color: #1c232f;
      position: relative; }
      .pc-horizontal .topbar .pc-navbar > .pc-hasmenu:hover > .pc-link .material-icons-two-tone {
        background-color: #1c232f; }
    .pc-horizontal .topbar .pc-navbar > .pc-hasmenu:hover > .pc-submenu {
      z-index: 1;
      padding-top: 60px;
      top: 0;
      left: -10px; }
  .layout-horizontal-v2 .topbar .pc-navbar .pc-item > .pc-link .material-icons-two-tone {
    background-color: #778290; }
  .layout-horizontal-v2 .topbar .pc-navbar .pc-item.active > .pc-link, .layout-horizontal-v2 .topbar .pc-navbar .pc-item:active > .pc-link, .layout-horizontal-v2 .topbar .pc-navbar .pc-item:focus > .pc-link, .layout-horizontal-v2 .topbar .pc-navbar .pc-item:hover > .pc-link {
    color: #7267EF; }
    .layout-horizontal-v2 .topbar .pc-navbar .pc-item.active > .pc-link .material-icons-two-tone, .layout-horizontal-v2 .topbar .pc-navbar .pc-item:active > .pc-link .material-icons-two-tone, .layout-horizontal-v2 .topbar .pc-navbar .pc-item:focus > .pc-link .material-icons-two-tone, .layout-horizontal-v2 .topbar .pc-navbar .pc-item:hover > .pc-link .material-icons-two-tone {
      background-color: #7267EF; }
  .layout-horizontal-v2 .topbar .pc-navbar > .pc-hasmenu:hover > .pc-submenu {
    padding-top: 15px;
    top: 100%;
    left: 0; } }

@media (max-width: 1024px) {
  .pc-horizontal .topbar {
    display: none;
    height: auto; }
    .pc-horizontal .topbar .container {
      max-width: 100%; }
    .pc-horizontal .topbar .pc-item {
      display: block; }
    .pc-horizontal .topbar .pc-navbar > .pc-item .pc-link {
      padding: 12px 10px; }
    .pc-horizontal .topbar .pc-navbar > .pc-item:first-child > .pc-link {
      padding-left: 10px; }
    .pc-horizontal .topbar .pc-item .pc-submenu {
      display: none; }
    .pc-horizontal .topbar .pc-item.pc-trigger > .pc-submenu {
      display: block; }
    .pc-horizontal .topbar .pc-submenu {
      position: relative;
      width: calc(100% - 30px);
      padding-top: 0;
      float: none;
      padding-bottom: 0;
      background: transparent;
      box-shadow: none;
      left: 30px; }
      .pc-horizontal .topbar .pc-submenu .pc-submenu {
        left: 20px;
        top: 0; }
      .pc-horizontal .topbar .pc-submenu .pc-link {
        color: #b5bdca; }
    .pc-horizontal .topbar.mob-sidebar-active {
      display: block;
      overflow-y: auto;
      max-height: calc(100vh - 70px); }
    .pc-horizontal .topbar > .container {
      position: relative;
      z-index: 5;
      background: inherit; }
  .pc-horizontal .pc-header.mob-header-active .container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; }
    .pc-horizontal .pc-header.mob-header-active .container > .header-wrapper {
      padding-left: 15px;
      padding-right: -15px; }
      .pc-horizontal .pc-header.mob-header-active .container > .header-wrapper .m-header {
        display: none; }
  .pc-horizontal .pc-container {
    top: 70px; }
  .pc-horizontal.layout-topbar .pc-header .pc-mob-drp > ul {
    display: block; }
    .pc-horizontal.layout-topbar .pc-header .pc-mob-drp > ul .pc-h-item .pc-head-link {
      display: flex; }
    .pc-horizontal.layout-topbar .pc-header .pc-mob-drp > ul .pc-h-item:not(:hover) .pc-head-link {
      color: #525b69; } }
